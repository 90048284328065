/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style/userscan.scss"
import moment from "moment"
import * as Yup from "yup"
import jwt_decode from "jwt-decode"
import {useNavigate, useParams} from "react-router-dom"
import call from "./images/icon-call.svg"
import logo from "./images/logo.svg"
import InvalidImage from "./images/invalid-qr.svg"
import QrUserDetailsInput from "../../../_metronic/Shared-Component-formik/input/QrUserDetailsInput"
import {Formik, Form} from "formik"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import {isMobile} from "react-device-detect"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import CommonPage from "../calling/CommonPage"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {TurnServerAction} from "../../../_metronic/redux/actions/LoaderAction"
import {toast} from "react-hot-toast"
import {userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const UserScanQrCode = () =>
{
	const {token, detailsToken} = useParams ()
	const token_decoded = token !== "qrcode_expired" && jwt_decode (token)
	
	const detail_token_decoded = detailsToken !== "expired" && jwt_decode (detailsToken)
	
	const navigate = useNavigate ()
	const  dispatch = useDispatch ()
	const [webrtcConfig, setwebrtcConfig] = useState ()
	const [getBrowser, setgetBrowser] = useState ("")
	console.log (`file: UserScanQrCode.js:32 ===> getBrowser`, getBrowser)
	
	let initialValues = {}
	let valSch = {}
	
	token_decoded?.fields?.map ((e) =>
	{
		// assign initial val
		initialValues[Object.keys (JSON.parse (e))[0]] = ""
		
		// assign val schema
		valSch[Object.keys (JSON.parse (e))[0]] = ValSchemaList (
			Object.values (JSON.parse (e))[0] == "email"
				? 1
				: Object.values (JSON.parse (e))[0] == "contact_no"
					? 4
					: 0,
			Object.values (JSON.parse (e))[0],
			"",
			"",
			Object.keys (JSON.parse (e))[0]
		)
	})
	let validationSchema = Yup.object (valSch)
	
	const fun = () =>
	{
		if ((navigator.userAgent.indexOf ("Opera") || navigator.userAgent.indexOf ("OPR")) != -1)
		{
			setgetBrowser ("Opera")
		} else if (navigator.userAgent.indexOf ("Edg") != -1)
		{
			setgetBrowser ("Edge")
		} else if (navigator.userAgent.indexOf ("Chrome") != -1)
		{
			setgetBrowser ("Chrome")
		} else if (navigator.userAgent.indexOf ("Safari") != -1)
		{
			setgetBrowser ("Safari")
		} else if (navigator.userAgent.indexOf ("Firefox") != -1)
		{
			setgetBrowser ("Firefox")
		} else if (navigator.userAgent.indexOf ("MSIE") != -1 || !!document.documentMode == true)
		{
			//IF IE > 10
			setgetBrowser ("IE")
		} else
		{
			setgetBrowser ("unknown")
		}
	}
	// turn data
	const getTurnDetails = async () =>
	{
		// const apiRes = await AxiosMethod(`serverDetails`, `GET`)
		// let configuration = {
		//   iceServers: [
		//     {
		//       urls: apiRes.data.data.url,
		//       ...(apiRes.data.data.username && {
		//         username: apiRes.data.data.username,
		//       }),
		//       ...(apiRes.data.data.password && {
		//         credential: apiRes.data.data.password,
		//       }),
		//     },
		//   ],
		//   // bundlePolicy: "max-bundle",
		//   // rtcpMuxPolicy: "negotiate",
		// }
		const apiRes = await AxiosMethod (`serverDetails/iceserver`, `GET`);
		
		let data = apiRes?.data;
		if (data && typeof data?.webIceConfig === "object")
		{
			let configuration = data.webIceConfig;
			console.log ("set ice server config", configuration.iceServers)
			dispatch (TurnServerAction (configuration))
			setwebrtcConfig (configuration)
		}
		if (JSON.parse (apiRes.request.response).status === "error")
		{
			console.error ("use default stun server", apiRes.request);
			dispatch (TurnServerAction ({iceServers: [{urls: "stun:stun.l.google.com:19302"}]}))
		}
	}
	useEffect (() =>
	{
		fun ()
		console.log (`file: UserScanQrCode.js ===> token_decoded`, token_decoded)
		console.log (`file: UserScanQrCode.js ===> detail_token_decoded`, detail_token_decoded)
		//getTurnDetails ()
	}, [])
	
	const [toastMsz, setToastMsz] = useState ()
	const renderToast = () =>
	{
		toast.dismiss ()
		toast (
			(t) => (
				<div className="d-flex justify-content-between">
					{toastMsz}
					
					<a className="ms-2" onClick={() => toast.dismiss (t.id)}>
            <span className="svg-icon svg-icon-3">
              <svg
	              xmlns="http://www.w3.org/2000/svg"
	              width={55}
	              height={55}
	              fill="currentColor"
	              className="bi bi-x-circle"
	              viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
	                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
					</a>
				</div>
			),
			{
				duration: 10000,
				position: "top-center",
				style: {
					border: "1px solid orange",
					color: "orange",
				},
			}
		)
		// return <ConfirmModel type="info" delMsz={delMsz} show={delId} onHide={setdelId} />
	}
	const checkPer = async () =>
	{
		let arr = [{name: "camera"}, {name: "microphone"}, {name: "notifications"}]
		let values = []
		for (let index = 0; index < arr.length; index++)
		{
			const element = arr[index]
			await navigator.permissions.query (element).then ((res) =>
			{
				if (values.length === 0)
				{
					let value = {
						name: res.name,
						permission: res.state,
					}
					values.push (value)
				} else
				{
					let filter = values.findIndex ((value1) =>
					{
						return value1.name == res.name
					})
					if (filter === -1)
					{
						let value = {
							name: res.name,
							permission: res.state,
						}
						values.push (value)
					} else
					{
						values[filter].permission = res.permission
					}
				}
			})
		}
		return values
	}
	const checkMedia = async (abc = {}) =>
	{
		if (getBrowser == "Chrome" || getBrowser == "Opera")
		{
			setToastMsz ()
			let perRes = await checkPer ()
			
			if (perRes[0]?.permission === "granted" && perRes[1]?.permission === "granted")
			{
				Object.assign (abc, {
					qrOrgName: detail_token_decoded.qrOrgName,
					qrcodeName: detail_token_decoded.qrcodeName,
					door_id: token_decoded.door_id,
					//defaultstaff: detail_token_decoded?.defaultstaff?.full_name,
					//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
					defaultstaff: detail_token_decoded?.defaultstaff,
					lastcalled: detail_token_decoded?.lastcalled,
					lastattendedby: detail_token_decoded?.lastattendedby,
				})
				navigate ("/calling", {
					state: {
						staffToCall: detail_token_decoded?.staffsArr,
						qrCodeId: detail_token_decoded?.qrcodeId,
						orgId: token_decoded?.organizationId,
						door_id: token_decoded.door_id,
						//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
						userInfo: abc,
						webrtcConfig: webrtcConfig,
						callingStatus: "O"
						
					},
				})
			}
			if (perRes[0]?.permission == "denied" || perRes[1]?.permission == "denied")
			{
				console.log (`denied`)
				
				setToastMsz (
					"We need access to your camera and microphone. Click the LOCK icon in your browser's address bar > click on permission > reset permissions > refresh page"
				)
			}
			if (perRes[0]?.permission == "prompt" && perRes[1]?.permission == "prompt")
			{
				console.log (`prompt`)
				
				setToastMsz ("Please allow camera and microphone permission whenever asked.")
				navigator.mediaDevices.getUserMedia ({
					video: {
						width: {
							min: "300",
							max: "640",
						},
						height: {
							min: "200",
							max: "480",
						},
					},
					audio: true,
				}).then ((currentStream) =>
				{
					setToastMsz ()
					toast.dismiss ()
					Object.assign (abc, {
						qrOrgName: detail_token_decoded.qrOrgName,
						qrcodeName: detail_token_decoded.qrcodeName,
						//defaultstaff: detail_token_decoded?.defaultstaff?.full_name,
						//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
						defaultstaff: detail_token_decoded?.defaultstaff,
						lastcalled: detail_token_decoded?.lastcalled,
						door_id: token_decoded.door_id,
						lastattendedby: detail_token_decoded?.lastattendedby,
					})
					navigate ("/calling", {
						state: {
							staffToCall: detail_token_decoded?.staffsArr,
							qrCodeId: detail_token_decoded?.qrcodeId,
							orgId: token_decoded?.organizationId,
							door_id: token_decoded.door_id,
							//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
							userInfo: abc,
							webrtcConfig: webrtcConfig,
							callingStatus: "O"
						},
					})
				})
			}
		} else
		{
			console.log (`Browser chrome or opera`)
			
			Object.assign (abc, {
				qrOrgName: detail_token_decoded.qrOrgName,
				qrcodeName: detail_token_decoded.qrcodeName,
				//defaultstaff: detail_token_decoded?.defaultstaff?.full_name,
				//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
				defaultstaff: detail_token_decoded?.defaultstaff,
				lastcalled: detail_token_decoded?.lastcalled,
				door_id: token_decoded.door_id,
				lastattendedby: detail_token_decoded?.lastattendedby,
			})
			navigate ("/calling", {
				state: {
					staffToCall: detail_token_decoded?.staffsArr,
					qrCodeId: detail_token_decoded?.qrcodeId,
					orgId: token_decoded?.organizationId,
					door_id: token_decoded.door_id,
					//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
					userInfo: abc,
					webrtcConfig: webrtcConfig,
					callingStatus: "O"
					
				},
			})
		}
	}
	
	return (
		<>
			{/*{isMobile ? (*/}
			{
				detail_token_decoded.qrcodeStatus === "active" &&
				Math.round (Date.now ()) > token_decoded.iat ? (
					<div className="main-wrap">
						<div className="container">
							<div className="row">
								<div className="col-xxl-4 col-lg-5 col-md-6 m-auto text-center">
									<a>
										<img src={logo} alt=""/>
									</a>
									<h3 className="mt-5 pt-5">
										<span className="d-block">Enter Your</span> Booking Details
									</h3>
									<div className="mt-5 pt-5">
										<Formik
											initialValues={initialValues}
											validationSchema={validationSchema}
											onSubmit={checkMedia}
										>
											{(formik) =>
											{
												return (
													<Form onSubmit={formik.handleSubmit} autoComplete="off">
														{token_decoded?.fields?.map ((e) =>
														{
															return (
																<QrUserDetailsInput
																	key={Object.keys (JSON.parse (e))}
																	type={Object.values (JSON.parse (e))}
																	label={Object.keys (JSON.parse (e))}
																	labelClassName="input-text"
																	name={Object.keys (JSON.parse (e))}
																	placeholder=" "
																/>
															)
														})}
														
														<button
															type="submit"
															className="btn btn-primary w-100 color-primary mt-50"
														>
															<img src={call} alt=""/> Call
														</button>
													</Form>
												)
											}}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : Math.round (Date.now ()) < token_decoded.iat ? (
					//if scan qr before time
					<CommonPage
						displayDiv={
							<>
								<div className="text-center thanks_text">
									<img src={logo} alt=""/>
									<h2 className="mt-5 pt-5">
										This Qr code will be available after
										<span className="d-block">
                      {moment (token_decoded.iat).utcOffset (userUtcOffset).format ("DD/MM/yyyy HH:mm")}
                    </span>
									</h2>
								</div>
							</>
						}
					/>
				) : (
					//qr expire/invalid
					<CommonPage
						displayDiv={
							<>
								<img src={InvalidImage} alt="" className="image_call"/>
								<div className="text-center thanks_text">
									<img src={logo} alt=""/>
									<h2 className="mt-5 pt-5">
										{!token_decoded ? "QR Code" : "Invalid QR"}
										<span className="d-block">{!token_decoded ? "Expired" : "Code"}</span>
									</h2>
								</div>
							</>
						}
					/>
				)
				
			// ) : (
			// 	// <ScanFromMobile />
			// 	<CommonPage
			// 		displayDiv={
			// 			<>
			// 				<div className="text-center thanks_text">
			// 					<img src={logo} alt=""/>
			// 					<h2 className="mt-5 pt-5">Please scan Qr from mobile device.</h2>
			// 				</div>
			// 			</>
			// 		}
			// 	/>
			// )}
			}
			{toastMsz && renderToast ()}
		</>
	)
}

export default UserScanQrCode
