// MAIN ROOM WRAPPER. IT CONTAINS ALL THE LOGIC THAT IS NEEDED TO CONNECT OUTGOING OR INCOMING CALLS.
// IT WILL ENABLE USER TO TALK WITH THE REMOTE USER.
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VideoContext from "./VideoContext";
import store from "../../../_metronic/redux/store";
import { globals } from "../../utils/globals";
import { getLoggedInUser } from "../../services/login.service";
import { useSelector } from "react-redux";
import {
	socket,
	insertCallRecordIntoFirestore,
	updateCallStatus,
	updateCallStatusByUserId,
} from "../../services/helper.service";
import { v4 as uuidv4 } from "uuid";
import { database } from "../../services/firebase.service";
import { child, off, onValue, ref } from "firebase/database";
import { httpRequest } from "../../services/network.service";

let myPeer;
let localStream_id;

const configuration =
{
	iceServers:
	[
		{
		    urls: ["stun:stun.l.google.com:19302"],
		},
	],
}

const VideoState = ({children, isCallStatus}) =>
{
	// GET THE CURRENT LOCATION AND STATE FROM REACT ROUTER
	const location = useLocation();
	const {state} = location;

	// GET THE NAVIGATE FUNCTION FROM THE REACT ROUTER HOOK
	const navigate = useNavigate();
	const isItVideoCall = useRef(false); // TRUE IF IT'S A VIDEO CALL.

	// EXTRACT THE EMAIL FROM THE REDUX STORE STATE USING THE USESELECTOR HOOK
	const email = useSelector((state) => state?.AuthReducer?.email);
	const user = useSelector((state) => state?.AuthReducer?.userData)

	const [call, setCall] = useState({})
	const [callAccepted, setCallAccepted] = useState(false)
	const [stream, setStream] = useState()
	const [remoteUsername, setRemoteUsername] = useState("")
	const [callEnded, setCallEnded] = useState(false)
	const [me, setMe] = useState("")
	const [username, setUsername] = useState("")
	const [remoteUserSocketId, setRemoteUserSocketId] = useState (null)
	const [callId, setCallId] = useState(null);
	const [callStartTime, setCallstarttime] = useState(null);
	const [myVdoStatus, setMyVdoStatus] = useState(true)
	const [userVdoStatus, setUserVdoStatus] = useState(false)
	const [myMicStatus, setMyMicStatus] = useState(true)
	const [userMicStatus, setUserMicStatus] = useState()
	const [staffUnavailable, setStaffUnavailable] = useState(false)
	const [message, setMessage] = useState ("")
	const [callRejected, setcallRejected] = useState()
	const [isSafariBrowser, setisSafariBrowser] = useState(false)
	const [webrtcState, setwebrtcState] = useState("Connecting...");
	const [callStatus, setCallStatus] = useState ("");
	const [isCallConnected, setIsCallConnected] = useState (false);
	const [frontCam, setfrontCam] = useState(true)
	const [callType, setCallType] = useState (isItVideoCall.current ? "video" : "audio");
	const [phoneSpeaker, setPhoneSpeaker] = useState ("phone");
	const [supportsMultipleCameras, setSupportsMultipleCameras] = useState (false);

	// SET INITIAL CONSTRAINTS FOR AUDIO ONLY
	const initialAudioConstraints =
	{
		audio:
		{
			echoCancellation: {exact: true},
			noiseSuppression: {exact: true},
			// autoGainControl: {exact: true},
		},
	};

	// SET INITIAL CONSTRAINTS FOR VIDEO
    const initialVideoConstraints =
    {
        video:
	    {
	        width:
	        {
	            min: "300",
	                max: "640",
	        },
	        height:
	        {
	            min: "200",
	                max: "480",
	        },
	        echoCancellation: {exact: true},
	        noiseSuppression: {exact: true},
	        // autoGainControl: {exact: true},
	    },
    };
	const myVideo = useRef()
	const userVideo = useRef()
	const connectionRef = useRef()
	const call_detail_id = useRef()
	const localStream = useRef()

	let callRealTimeRef= null;

	// AUDIO CALL SECONDS
	const [sec, setSec] = useState(0)
	const [isRunning, setRunning] = useState(false)

	// TIMEOUT IF CALL NOT RECEIVED
    const remainingTimeRef = useRef (90000); // INITIAL TIMER: 90 SECONDS (90000MS)
    const timerRef = useRef(null);
	const [staffTimeOut, setstaffTimeOut] = useState(false)

	const handleStartButton = () =>
	{
		setRunning (true)
	}
	const handleResetButton = () =>
	{
		setSec(0)
		setRunning(false)
	}

	// THIS HOOK TO USE THE CALL TIMER START WHEN CALL IS CONNECTED.
	useEffect(() =>
	{
		if (isRunning)
		{
			const intervalId = setTimeout(() =>
			{
			    setSec ((prevCount) => prevCount + 1)
			}, 1000)

			// REMOVE TIMER INTERVAL.
		    return () =>
		    {
		        clearTimeout (intervalId)
		    }
		}
	}, [sec, isRunning])

	// SOCKET EVENTS
	useEffect(() =>
	{
        try {

            console.log(`1 - location state.webrtcConfig`, state?.webrtcConfig);

            // ICE SERVER CONFIG ADDRESS
            let iceServerConfig = state?.webrtcConfig || store.getState().LoaderReducer.turnDetails || configuration
            console.log(`1.1 - location iceServerConfig.iceServerConfig`, iceServerConfig);

            // CONNECTION START TO RTC PEER
            myPeer = new RTCPeerConnection(iceServerConfig);

            console.log(`2 RTCPeerConnection ===> myPeer`, myPeer);

            // BROWSER SUPPORT FACINGMODE OR NOT
            const supports = navigator.mediaDevices.getSupportedConstraints()
            if (!supports["facingMode"]) {
                alert("Browser Not supported!")
                return
            }
            console.log(`3 navigator.mediaDevices  ===> Devices`, navigator.mediaDevices)

            // FOR SAFARI
            let userAgent = navigator.userAgent
            if (userAgent.match(/chrome|chromium|crios/i)) {
            } else if (userAgent.match(/firefox|fxios/i)) {
            } else if (userAgent.match(/safari/i)) {
                setisSafariBrowser(true)
            }

            startCallOutTimer (remainingTimeRef.current); // 90 SEC TIMER

            // INITIALIZE SOCKET
            if (globals.socket != null && globals.socket.connected) {
                console.log("VideoContext -> socket already connected found.", socket);
                // socket = globals.socket;
                globals.socket = socket;
            } else {
                console.log("VideoContext socket try to connected.");
                globals.socket = socket;
            }

            // PEER EVENTS
            myPeer.oniceconnectionstatechange = (e) =>
            {
                console.log("on connection state change we got this event", e)
            }
            myPeer.ontrack = (event) =>
            {
                //here we got remote stream
                console.log("webrtc_debug: mypeer.ontrack we got remote stream here")
                handleStartButton();

                // IF REMOTE USER STREAM IS AVAILABLE THEN ADD A NEW TRACK IN IT. OTHERWISE CREATE A NEW STREAM.
                if (userVideo.current && userVideo.current?.srcObject)
				{
                    console.log ("webrtc_debug: add new track to existing stream", event.track);
                    userVideo.current?.srcObject?.addTrack (event.track);
                } else {
                    console.log("webrtc_debug: create new stream", event.streams[0]);
                    userVideo.current.srcObject = event.streams[0];
                }
            }
            myPeer.onconnectionstatechange = (ev) =>
            {
                webrtcState !== myPeer.connectionState && setwebrtcState (myPeer.connectionState)
            }

            // SUBSCRIBE THE SOCKET EVENTS.
            subscibeSocketEvents();
        }
        catch (e)
        {
            console.log ("Error in VideoState useEffect", e);
        }
		return ()=>
		{
			// TERMINATE THE CALL CONNECTION.
			terminateCallConnection();
            clearTimeout (timerRef.current);
        }
	}, []);

    // FUNCTION TO START THE TIMER
    const startCallOutTimer = (time) =>
    {
        clearTimeout (timerRef.current); // CLEAR ANY EXISTING TIMER
        timerRef.current = setTimeout(() =>
        {
            console.log (">>>>>>>>>>>>>>>>>> Timer ended:", time);

            // IF CALLER THEN UPDATE CALL STATUS TO MISSED.
            if (call_detail_id.current)
			{
                // UPDATE CALL STATUS TO MISSED
                updateCallStatus (call_detail_id.current, "MISS").then(async () =>
                {
                    // INSERT CALL DETAIL IN FIRESTORE.
                    await insertCallRecordIntoFirestore (call_detail_id.current);
                });
				socket.emit ("endCall", remoteUserSocketId)
				
				//  NAVIGATE TO STAFF NOT AVAILABLE PAGE.
                navigate ("/staffnotavailable",
                    {
                        state:
                            {
                                userinfo: state?.userInfo,
                                reason: 'MISS',
                                call_id: call_detail_id.current,
                            },
                    })
                return;
            }
            setstaffTimeOut (true);
        }, time);
    };

	// SOCKET EVENTS
	const subscibeSocketEvents = ()=>
	{
		// YOUR SOCKET ID FOR BOTH ( OUTGOING-CALL AND INCOMING-CALL)
		socket.on ("me", getMyId);

		if (isCallStatus === "OUT" || state.callingStatus === "O")
		{
			console.log ("4 dial-call", state?.staffToCall, state?.userInfo, state);
			socket.on ("getCallIdFromServer", getCallIdFromServer);

			// HERE WE HAVE TO EMIT AVAILABLE STAFF ARRAY AND USER INFO WHICH WE ARE GETTING FROM PREVIOUS PAGE
			socket.emit ("dial-call", state?.staffToCall, state?.userInfo, state);
		}

		// THIS EVENT EMITTED WHEN NO STAFF IS AVAILABLE FOR OUTGOING-CALL.
		socket.on ("staff-unavailable", handleStaffUnavailable);

		// THIS EVENT EMITTED WHEN STAFF ACCEPTED CALL FOR OUTGOING-CALL.
		socket.on ("staff-details", handleStaffDetail);

		// THIS EVENT WILL BE EMITTED WITH "staff-details" EVENT. BUT THIS WILL BE RECEIVED BY CALLEE/STAFF
		// WHO HAS ANSWERED THE CALL.
		socket.on ("caller-details", handleCallerDetail);

		socket.on ("offer", handleUserCall);

		// THIS EVENT WILL BE TRIGGERED WHEN THERE IS ICE CANDIDATE INFORMATION TO BE SHARED FOR BOTH.
		socket.on ("offer-candidate", handleIceCandidateEvent);

		// EVENT EMITTED WHEN ANYONE CHANGES THEIR CAMERA AND MIC STATUS FOR BOTH.
		socket.on ("updateUserMedia", handleUserMediaUpdate);

		// EVENT EMITTED WHEN STAFF ACCEPT CALL AND EMITTED ANSWER SDP FOR OUTGOING-CALL.
		socket.on ("answer", handleCallAccepted);

		// EVENT EMITTED WHEN CALL ENDED FOR BOTH.
		socket.on ("endCall", handleEndCall);

		socket.on ('disconnect', function()
		{
			console.log ('debug: step 3.0 Socket disconnected');
		});

		socket.on ('reconnect_attempt', function(attemptNumber)
		{
			console.log (`debug: step 2.1 Reconnection attempt #${attemptNumber}`);
		});

		socket.on ('reconnect_failed', function()
		{
			console.log ('debug: step 2.2 Reconnection failed');
		});

		socket.on ('reconnect', function()
		{
			console.log ('debug: step 2.0 Successfully reconnected to the server');
		});
		socket.on ('connect',()=> {
			console.log ('debug: step 1.0 socket connected with main server:\n', socket);
		});

		socket.on ('connect_error',(err)=> {
			console.log (`debug: step 1.1 main server socket connect_error due to: ${err.message}`);
		});

		socket.on ('connect_timeout',()=> {
			console.log (`debug: step 1.2 main server socket connection timeout`);
		});

        socket.on ('timerIncrement', () =>
        {
            console.log ("Received timer increment event from socket");

            // INCREMENT REMAINING TIME BY 60 SECONDS (60000MS)
            const newRemainingTime = remainingTimeRef.current;// + 60000;

            // UPDATE REF
            remainingTimeRef.current = newRemainingTime;

            // RESTART TIMER WITH THE NEW REMAINING TIME
            startCallOutTimer (newRemainingTime);
        });
	}

	// WHEN REMOTE USER'S SOCKET ID IS AVAILABLE THE SUBSCRIBE THE RTC EVENTS THAT REQUIRE REMOTE SOCKET ID.
	useEffect (() =>
	{
		// START THE CALL CONNECTION WHEN REMOTE USER'S SOCKET ID IS AVAILABLE.
		if (remoteUserSocketId)
		{
			// CALL TYPE IS VIDEO THEN GET THE VIDEO STREAM ELSE AUDIO STREAM.
			if (callType === "video")
			{
				initializeCamera();
			}
			else
			{
				startAudioOnlyCall().then();
			}
			console.log ("remote user's socket id is available now", remoteUserSocketId);
			myPeer.onicecandidate = (e) =>
			{
				console.log ("myPeer.onicecandidate event", e)
				if (e.candidate)
				{
					let obj =
						{
							candidate: e.candidate,
							callTo: remoteUserSocketId,
						}
					console.log(`candidate address`, e.candidate.address)
					console.log(`candidate relatedAddress`, e.candidate.relatedAddress)
					console.log
					(
						"myPeer.onicecandidate get candidate after setting local desc and performed socket emit event",
						obj
					)
					socket.emit ("offer-candidate", obj)
					console.log ("webrtc", "step6-sending-offer-candidate");
				}
			}
			myPeer.onnegotiationneeded = () =>
			{
				console.log ("webrtc_debug:", "step-negotiation-needed", remoteUserSocketId);
				createOfferCallUser (remoteUserSocketId);
			}
		}
	}, [remoteUserSocketId])


	// WHEN CALL_ID IS AVAILABLE THEN SUBSCRIBE TO THE CALL DETAIL FIRESTORE IN THE DATABASE. THIS WILL BE USED TO
	// LISTEN FOR CHANGES IN THE CALL STATUS.
	useEffect (() =>
	{
		if (callId)
		{
			// REFERENCE TO 'CALL' NODE IN THE DATABASE
			const databaseRef = ref (database, 'call');
			const callRealTimeRef = child (databaseRef, callId);
			// const callStatusRef = child (callRealTimeRef, "callstatus");

			// LISTEN FOR CHANGES IN 'CALL' NODE
			onValue (callRealTimeRef, handleCallDetailSnapshot);

			// CLEAN UP SUBSCRIPTION WHEN COMPONENT UNMOUNTS
			return () =>
			{
				// UNSUBSCRIBE FROM DATABASE CHANGES
				// THIS ENSURES THAT WE DON'T HAVE ANY MEMORY LEAKS
				off (callRealTimeRef, 'value', handleCallDetailSnapshot);
			};
		}
	}, [callId]);

	// HANDLE SNAPSHOT UPDATES: IF THE CALL STATUS IS "ANSWERED" AND hasAnswered FLAG IS FALSE THEN
	// TERMINATE THE INCOMING CALL SCREEN.
	const handleCallDetailSnapshot = (snapshot) =>
	{
		const data = snapshot.val();

		let default_staff_id = state?.userInfo?.default_staff_id;
		let isDefaultStaffInStaffList = data?.isDefaultStaffInStaffList;
		
		console.log ("handleCallDetailSnapshot:", data.rejectedby_user_id, "isCallStatus", isCallStatus);
		console.log ("rejectedby_user_id", data?.rejectedby_user_id?.length, "staffids", data.staffids?.length, "isDefaultStaffInStaffList", isDefaultStaffInStaffList)

		if (data.callstatus === "CONNG")
		{
			setCallStatus ("We are connecting your call. Please wait . . .");
		}
		else if (data.callstatus === "RING")
		{
			setCallStatus ("Ringing");
		}
		else if (data.callstatus === "ACCPT")
		{
			setCallStatus ("We are about to connect you with an agent . . .");
			clearTimeout (timerRef.current);
		}
		
		// CHECK IF CALL STATUS IS 'ENDED' THEN TERMINATE THE CALL.
		if (data.callstatus === 'ENDED' && isCallStatus === "IN")
		{
			console.log ("call Ended by realtime database");
			// END THE CALL
			navigate ("/dashboard");
		}
		
		// CHECK IF CALL STATUS IS 'MISS' THEN TERMINATE THE CALL.
		if (data.callstatus === 'MISS' && isCallStatus === "IN")
		{
			console.log ("call MISSED by realtime database");
			
			// END THE CALL
			navigate ("/dashboard");
		}
		
		
		// IF CALL STATUS IS 'REJCT' AND OUTGOING CALLER THEN CHECK IF ALL STAFF ARE REJECTED THEN END THE CALL.
		if (typeof data.rejectedby_user_id !== "undefined" && isCallStatus === "OUT")
		{
			console.log("rejectedby_user_id", data.rejectedby_user_id.length, "staffids", data.staffids.length)

			// IF ALL REJECTED STAFF ARE NOT IN RINGING STATUS, END THE CALL
			if ( data.rejectedby_user_id.length === data.staffids.length)
			{
				// END THE CALL IF ALL STAFF REJECTED THE CALL.
				if (isDefaultStaffInStaffList)
				{
                    handleEndCall (true, callId, data.reason);
                }
			}
		}
		
		// IF DEFAULT STAFF RECEIVED THE SEPERATE NOTIFICATION THEN REJECT THE CALL OR MISS THE CALL THEN END THE CALL.
		// FIRSTLY WE CHECKED DEFAULT STAFF IS NOT IN STAFF LIST WHO RECEIVED THE CALL.
		if (!isDefaultStaffInStaffList)
		{
			const defaultStaffNotified = data?.defaultstaffnotified; // HOLD DEFAULT STAFF NOTIFIED ARRAY.
			console.log ("defaultStaffNotified", defaultStaffNotified);
			
			if (typeof defaultStaffNotified !== "undefined" && typeof defaultStaffNotified.callstatus !== "undefined")
			{
				console.log("defaultStaffNotified.callstatus", defaultStaffNotified.callstatus);
				
				// IF DEFAULT STAFF REJECTED THE CALL THEN END THE CALL.
				if (defaultStaffNotified.callstatus === "REJTD" || defaultStaffNotified.callstatuses.some (status => status.indexOf("REJTD") !== -1))
				{
					handleEndCall (true, callId, "REJCT");
					return;
				}
				
				// IF DEFAULT STAFF MISSED THE CALL THEN END THE CALL.
				if (defaultStaffNotified.callstatus === "REJTD" || defaultStaffNotified.callstatuses.some (status => status.indexOf("MISS") !== -1))
				{
					handleEndCall (true, callId, "MISSED");
					return;
				}
			}
		}
		console.log("data", data);

		// CHECK THE notified_user_id ARRAY LENGTH. IF IT'S GREATER THAN 0 THEN CHECK EACH INDEX OF callstatuses IS
		// MISSED ALL INDEX THEN END THE CALL.
		if (typeof data.notified_user_id !== "undefined" && isCallStatus === "OUT")
		{
			//console.log ("notified_user_id", Object.keys(data.notified_user_id)?.length, "staffids",
			// data.staffids.length)

			// HOLD MISSED STATUS COUNT.
			let missedStatusCount = 0;

			// ITERATE OVER THE NOTIFIED_USER_ID OBJECT
			Object.keys(data.notified_user_id).forEach(userId =>
			{
				let userData = data.notified_user_id[userId];
			
				if (userData)
				{
					// CHECK IF ANY OF THE CALLSTATUSES CONTAIN "MISS"
					if (userData.callstatuses.some (status => status.indexOf ("MISS") !== -1))
					{
						missedStatusCount++;
					}
				}
			});
			console.log ("Number of staff missed the call", missedStatusCount);

			// IF ALL STAFF MISS THE CALL THEN END THE CALL.
			if (missedStatusCount === data.staffids?.length )
			{
				console.log ("All staff missed the call");
				
				// END THE CALL IF ALL STAFF REJECTED THE CALL.
				if (isDefaultStaffInStaffList)
				{
					handleEndCall(true, callId, "MISSED");
					return;
				}
			}
		}
	}

	// SET MY SOCKET ID ON STATE
	const getMyId = (data) =>
	{
		console.log ("hy, it's my Socket ID =====>>>>>>> socket id and data:", data)
		setMe (data) // SET STATE.
	}

	// SET CALL DETAIL ID ON STATE
	const getCallIdFromServer = (data) =>
	{
		console.log ("hy, it's getCallIdFromServer =====>>>>>>> getCallIdFromServer  and data:",  data)
		setCallId (data) // SET STATE.
		call_detail_id.current = data;

		// USER OBJECT OF CURRENT USER TO CONTAIN STATIC FIELDS ONLY.
		globals.localUser =
		{
			room_id: data,
			origin: "WEB", // FROM WHERE THIS USER IS JOINING FROM? MOBILE APP OR WEB?
			...state?.userInfo, // CURRENT USER.
			stream_id: (stream != null ? stream?.id : uuidv4().toString() + ""),
			socket_id: (socket && typeof socket?.id !== "undefined" ? socket.id : null)
		}
		console.log ("globals.localUser",globals.localUser, "localStream_id", stream?.id);
		socket.emit ("join_room", globals.localUser); // LET'S JOIN THE ROOM.
	}

	// THIS FUNCTION CALL WHEN STAFF UNAVAILABLE.
	const handleStaffUnavailable = async (data) =>
	{
		console.log ("handleStaffUnavailable", data);

		// MAKE SURE data IS A VALID OBJECT.
		if (data && typeof data === "object" && Object.keys(data).length > 0)
		{
			const { call_id, reason, callstatus } = data;

			// UPDATE THE CALL DETAIL BY CALL DETAIL ID.
			// await updateCallFirestore (call_id, { reason: reason, callstatus: callstatus });

			let fireStoreCall_id = call_id?call_id: callId;

			// IF CALLER THEN NAVIGATE TO STAFF NOT AVAILABLE PAGE.
			if (fireStoreCall_id)
			{
				navigate ("/staffnotavailable",
					{
						state:
						{
							userinfo: state?.userInfo,
							reason: reason,
							call_id: fireStoreCall_id,
						},
					}
				)
				return;
			}
			setStaffUnavailable (true); // STAFF IS NOT AVAILABLE..

			setMessage (reason);
		}
		else
		{
			console.error ("staff unavailable. data is not valid", data);
		}
	}

	// THIS CODE WILL RUN FOR CALLER OF THE CALL.
	const handleStaffDetail =  (obj) =>
	{
		console.log ("webrtc", "step2-staff-details", obj)
		clearTimeout (timerRef.current); // CLEAR ANY EXISTING TIMER
		let answerCallStaff = obj.answerCallStaff;
		setRemoteUsername (answerCallStaff.email || answerCallStaff.full_name)
		console.log ("set remote user socket id_1", answerCallStaff.socketId);
		setRemoteUserSocketId (answerCallStaff.socketId);
		Object.assign (location.state, {staffs: obj.staffs})
	}

	// THIS WILL BE CALLED FROM SOCKET EVENT "caller-details". IT WILL BE TRIGGERED FROM "call-answered" FROM SERVER.
	// THIS FUNCTION WILL RECEIVE THE SOCKET ID OF THE CALLER AKA REMOTE USER.
	// THIS WILL BE EXECUTED FOR THE CALLEE/STAFF WHO HAS ANSWERED THE CALL.
	const handleCallerDetail = (data) =>
	{
		console.log ("set remote user socket id_2", data.socket_id);
		setRemoteUserSocketId (data.socket_id);
	}

	// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
	// TO ADD INCOMING ICE CANDIDATE.
	const handleIceCandidateEvent = (incoming) =>
	{
		// LOG DETAILS OF THE INCOMING OFFER CANDIDATE
		console.log ("webrtc", "step4-received-offer-candidate-event", incoming);
		try
		{
			// IF PEER CONNECTION IS AVAILABLE, ADD THE CANDIDATE
			if (myPeer !== null)
			{
				console.log("Adding ICE candidate");
				const candidate = new RTCIceCandidate (incoming.candidate); // TBD
				console.log ("debug_icecandidate, Adding ICE candidate");
				myPeer.addIceCandidate (candidate).catch ((e) =>
				{
					// TODO: ICE CANDIDATE ERROR. TAKE USER TO CALL FAIL. OR TRY TO RECONNECT.
					console.error ("ice-candidate-error ", e);
				});
			}
			else
			{
				console.log ("Peer connection object is null");
			}
		}
		catch (error)
		{
			// LOG ERROR IF ANY
			console.log ("ICE candidate caller error:", error);
		}
	}

	// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
	// INCOMING-CALL | SET THE CALL STATE CALLER INFO AND ANSWER SIGNAL.
	// OUTGOING-CALL | HANDLE THE CALL OFFER FROM REMOTE USER. REMOTE USER WILL CREATE AN OFFER AND CURRENT USER
	// WILL SEND AN ANSWER TO CALLEE.
	const handleUserCall = async (data) =>
	{
        console.log ("SOCKET_ON_CALL_USER_EVENT => get offer", data);
        if (data.signal.type  === "offer")
        {
			// IF TIMER IS RUNNING THEN CLEAR THE TIMER OUT REFERENCE.
			if (timerRef.current)
            clearTimeout (timerRef.current);

			// CREATE ANSWER FOR INCOMING CALL.
            createAnswerCallUser (data).then()
            console.log ("SOCKET_ON_CALL_USER_EVENT => get offer", "outgoing caller create answer");
        }

        if (isCallStatus === "OUT")
		{
            const {from, name: callerName, signal} = data;
			setCall({isReceivingCall: true, from, name: callerName, signal})
		}
	}

	const handleCallAccepted =  async (data) =>
	{
		console.log ("debug_icecandidate1.2", data);
		let mediaData = data.data;
		setCallAccepted (true);
		
		// IF TIMER IS RUNNING THEN CLEAR THE TIMER OUT REFERENCE.
		if (timerRef.current)
		clearTimeout (timerRef.current); // CLEAR ANY EXISTING TIMER
		
		console.log ("answer sdp data from socket", mediaData.signal, data.socket_id)
		console.log ("debug_icecandidate2, set remote description");
		await myPeer.setRemoteDescription (new RTCSessionDescription (mediaData.signal));
		const callStart = new Date();
		console.log("start call time is :", callStart)
		setCallstarttime (callStart);
	}

	// CALL REMOTE USER.
	const createOfferCallUser = (id) =>
	{
		console.log ("webrtc", "step-create-offer", id);
		console.log ("create Offer CallUser for create offer callee socket id:"+ id);

		try
		{
			// SEND OFFER OBJECT TO REMOTE USER.
			let offerParams =
				{
					offerToReceiveAudio: true, //(!isItVideoCall.current), // 1
					offerToReceiveVideo: (isItVideoCall.current), // 1
					voiceActivityDetection: true, //(!isItVideoCall.current),
					iceRestart: 1,
				}

			// setRemoteUserSocketId (id) // SET OTHER USER SOCKET ID.
			console.log (`webrtc_debug: createOffer ===> offerParams`, offerParams);

			// CREATE OFFER.
			myPeer.createOffer (offerParams).then (async (tempSdp) =>
			{
				await myPeer.setLocalDescription (tempSdp);
				let userSocketId = me? me: globals.socket?.id;
				let obj =
				{
					userToCall: id,
					signalData: tempSdp,
					from: userSocketId,
					name: remoteUsername,
					data: location.state,
					mediaStatus:
					{
						type: !isItVideoCall.current ? "mic" : "both",
						'myMediaStatus': !isItVideoCall.current ? true : [true, true]
					},
					callType: (isItVideoCall.current ? "video" : "audio")
				}
				console.log (`webrtc 7 here we emit call local description(sdp) which we are getting after creating offer`, obj)
				socket.emit ("offer", obj)
			}).catch ((e) => alert (e))
		}
		catch (error)
		{
			console.error("error code 106:", error);
		}
	}

	// CREATE THE ANSWER.
	const createAnswerCallUser = async (offer) =>
	{
		try
		{
			// CHECK IF PEER CONNECTION EXIST.
			if (myPeer)
			{
				console.log (`webrtc_debug: create_Answer_CallUser, data`, offer);

				// CREATE THE OFFER RTCSessionDescription THEN STORE THE REMOTE DESCRIPTION.
				try
				{
					// CREATE THE OFFER RTCSessionDescription THEN STORE THE REMOTE DESCRIPTION.
					let offerRemoteUser = new RTCSessionDescription
					({
						type: 'offer', // SPECIFY THE TYPE OF THE SESSION DESCRIPTION (OFFER/ANSWER)
						sdp: offer.signal.sdp   // THE SDP STRING
					});

					// SET THE REMOTE DESCRIPTION.
					await myPeer.setRemoteDescription (offerRemoteUser);
				}
				catch (e)
				{
					console.error ("create_Answer_CallUser_1 error", e);
				}

				// TRY TO CREATE ANSWER AND SEND IT TO REMOTE USER.
				try
				{
					let answer = await myPeer.createAnswer (); // TBD
					await myPeer.setLocalDescription (answer);

					let user_email = email;
					if (!user_email)
					{
						// GET CURRENT USER'S PROFILE DATA.
						let user = await getLoggedInUser();
						user_email = user?.email
					}
					let userSocketId = me? me: globals.socket?.id;

					// SET ANSWER OBJECT AND SEND IT TO REMOTE USER.
					const payload =
					{
						to: offer.from , // SOCKET ID OF REMOTE USER.
						caller: userSocketId, // SOCKET ID OF CURRENT USER.
						signal: {type: 'answer', 'sdp': answer.sdp}, // SESSION DESCRIPTION PROTOCOL INFORMATION.
						userName: "",
						type: !isItVideoCall.current ? "mic" : "both",
						'myMediaStatus': !isItVideoCall.current ? true : [true, true],
						staffs: offer.staffs,
						email: user_email,
                        callType: offer.callType
                    }

					console.log ("8 create emit answer call", payload, "get offer create answer", remoteUserSocketId, offer.from);
					socket.emit ("answer", payload) // SEND DATA TO REMOTE USER.
					globals.callStartTime = new Date();
					setIsCallConnected (true);
				}
				catch (e)
				{
					console.error ("create_Answer_CallUser_2 error", e);
				}
			}
			else
			{
				console.error ("peer not connected");
			}
		}
		catch (error)
		{
			console.error ("handle call offer error ", error);
		}
	}

	const handleEndCall = async (reject, call_id, rejectionDetail) =>
	{
		console.log ("reject", reject, call_id, rejectionDetail)
		setcallRejected (reject)
		if (reject)
		{
			// CREATE THE OBJECT FOR UPDATE THE CALL REJECTION REASON.
			let column = {"reason" : rejectionDetail.type};
			let updateID = call_id || rejectionDetail.call_id;

			// UPDATE THE CALL DETAIL BY CALL DETAIL ID.
			// updateCallFirestore (updateID, column);

			// INSERT CALL DETAIL IN FIRESTORE.
			await insertCallRecordIntoFirestore (updateID);
			terminateCallConnection();
		}
		else
		{
			leaveCall (reject)
		}
	}

	// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
	// UPDATE THE USER MEDIA STATUS ENABLE AND MUTE DETAIL.
	const handleUserMediaUpdate = ({type, currentMediaStatus}) =>
	{
		console.log("update user media calling>>>>>>>", {type, currentMediaStatus})
		if (currentMediaStatus !== null || currentMediaStatus.length !== 0)
		{
			switch (type)
			{
				case "video":
					setUserVdoStatus(currentMediaStatus)
					break
				case "mic":
					setUserMicStatus(currentMediaStatus)
					break
				default:
					setUserMicStatus(currentMediaStatus[0])
					setUserVdoStatus(currentMediaStatus[1])
					break
			}
		}
	}


    // EMIT UPDATEMYMEDIA EVENT WITH VIDEO STATUS TO SERVER AND TOGGLE THE VIDEO STATUS.
	const updateVideo = () =>
	{
        // EMIT UPDATEMYMEDIA EVENT WITH VIDEO STATUS TO SERVER
        socket.emit ("updateMyMedia",
        {
            type: "video",
            currentMediaStatus: !myVdoStatus,
            from: remoteUserSocketId,
        })
        //stream.getVideoTracks()[0].enabled = !currentStatus
        console.log ("webrtc_debug:", "update myMedia calling video>>>>>>>",
        {
            type: "video",
            currentMediaStatus: !myVdoStatus,
            from: remoteUserSocketId,
        })

        // STOP VIDEO TRACK IF ALREADY MYVDOSTATUS IS TRUE. OTHER-WISE INITIALIZE CAMERA.
        if (stream && myVdoStatus)
        {
            console.log ("webrtc_debug: stop video track", myVdoStatus);
            stopVideoTrack(); // STOP VIDEO TRACK.
        }
        else
        {
            // INITIALIZE VIDEO STREAM.
            initializeCamera();
        }

		// SET THE STATE OF VIDEO STATUS.
		setMyVdoStatus (!myVdoStatus)
	}

	// this method is used to mute/unmute our stream
	const updateMic = () => {
	setMyMicStatus((currentStatus) => {
	  socket.emit("updateMyMedia", {
	    type: "mic",
	    currentMediaStatus: !currentStatus,
	    from: remoteUserSocketId,
	  })
	  console.log("update myMedia calling audio>>>>>>>", {
	    type: "mic",
	    currentMediaStatus: !currentStatus,
	    from: remoteUserSocketId,
	  })
	  stream.getAudioTracks()[0].enabled = !currentStatus
	  return !currentStatus
	})
	}

	// HANDLE ANY CHANGE IN WEBRTC CONNECTION STATE. POSSIBLE STATES ARE:
	// CONNECTING | CONNECTED | DISCONNECTED | FAILED | FAILED | CLOSED
	useEffect(() =>
	{
	    if (webrtcState == "disconnected" || webrtcState == "failed" || webrtcState == "closed")
		{
			if (isCallStatus === "OUT")
			{
				console.error ("Webrtc connection has " + webrtcState);
				leaveCall ("Webrtc connection has " + webrtcState);
			}
			else
			{
				hangupCall();
			}
	    }
	}, [webrtcState])

	// FUNCTION TO STOP VIDEO TRACK
    const stopVideoTrack = () =>
    {
	    if (stream)
		{
			stream.getVideoTracks().forEach ((track) =>
			{
			    track.stop()
			})

            // REMOVE VIDEO TRACK FROM PEER CONNECTION IF REQUIRED
            myPeer.getSenders().forEach((sender) =>
            {
                if (sender.track && sender.track.kind === 'video')
                {
                    // myPeer.removeTrack (sender);
                }
            });
        }
    }

    // INITIALIZE THE CAMERA STEAM.
    const initializeCamera = async (flip) =>
    {
	    if (flip)
	    {
		    // STOP VIDEO TRACK IF ALREADY AVAILABLE.
		    if (stream)
		    {
                stopVideoTrack(); // STOP VIDEO TRACK.
		    }

		    // CHECK IF FRONT CAMERA IS AVAILABLE THEN SET THE FACING MODE.
		    setfrontCam (!frontCam)
	    }
	    await checkMediaDevices();

	    console.log ("webrtc_debug: initialize camera", initialVideoConstraints);

        // SET THE FACING MODE BASED ON frontCam STATE
        initialVideoConstraints.video.facingMode = frontCam ? "user" : "environment";

        try
        {
            const currentStream = await navigator.mediaDevices.getUserMedia ({...initialVideoConstraints});

            // SET THE VIDEO CALL FLAG TO TRUE.
            isItVideoCall.current = true;

            //GET THE VIDEO TRACK AND SET THE STREAM OBJECT.
            let videoTrack = currentStream.getVideoTracks()[0];

            // ADD VIDEO TRACK TO LOCAL STREAM
            localStream.current.addTrack(videoTrack);

            console.log("webrtc_debug: set current user's localStream.current ", localStream.current, currentStream.getVideoTracks()[0]);

            // ADD VIDEO TRACK TO EXISTING STREAM
            stream.addTrack(videoTrack);

            // VALIDATE THE VIDEO ELEMENT BEFORE SETTING THE SRC OBJECT.
            if (myVideo.current)
            {
                myVideo.current.srcObject = currentStream
            }

            // IF JUST FLIP CAMERA IS TRUE THEN REPLACE THE VIDEO TRACK IN PEER CONNECTION. OTHERWISE ADD NEW TRACK.
            if (!flip)
            {
                console.log ("webrtc_debug: add video track to peer");
                return myPeer.addTrack (videoTrack, currentStream);
            }
            else
            {
                console.log ("webrtc_debug: flip the camera");
                // const videoSender = myPeer.getSenders().find((sender) => sender.track?.kind === "video");
                // if (videoSender) {
                //     await videoSender.replaceTrack(videoTrack);
                // }
                return myPeer.getSenders().filter((sender) => sender.track?.kind == "video")[0].replaceTrack (currentStream.getVideoTracks()[0])
            }
        }
        catch (error)
        {
            console.error ("webrtc_debug: error initializing camera", error);
        }
    }

    // FUNCTION TO TOGGLE BETWEEN AUDIO AND VIDEO
    const toggleVideoCall = () =>
    {
        try
        {
            let formUser = me ? me : globals.socket?.id;

            // EMIT START VIDEO CALL EVENT TO REMOTE USER.
            socket.emit("isVideoCallStarted", {to: remoteUserSocketId, from: formUser});
            setCallType(prevState => (prevState === "audio") ? "video" : "audio"); // SET CALL TYPE TO VIDEO.

            // SWITCHING FROM VIDEO TO AUDIO
            initializeCamera(); // INITIALIZE VIDEO STREAM.

            // SET THE VIDEO STATUS TO TRUE IF IT IS FALSE.
            if (!myVdoStatus)
                setMyVdoStatus(true);

            // EMIT UPDATEMYMEDIA EVENT WITH VIDEO STATUS TO SERVER
            globals.socket.emit("updateMyMedia",
                {
                    type: "video",
                    currentMediaStatus: true,
                    from: remoteUserSocketId,
                });
        }
        catch (e)
        {
            console.log ("switch.VideoCall() error", e);
        }
    };

	// CHECK THE MEDIA DEVICES AND SHOW SWITCH CAMERA OPTION IF MULTIPLE CAMERAS ARE AVAILABLE.
	const checkMediaDevices = async() =>
	{
		try
		{
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoInputDevices = devices.filter (device => device.kind === 'videoinput');

			// MULTIPLE CAMERAS ARE AVAILABLE, SHOW SWITCH CAMERA OPTION
			if (videoInputDevices.length > 1)
			{
				setSupportsMultipleCameras (true);
			}
			else
			{
				// ONLY ONE CAMERA AVAILABLE, HIDE SWITCH CAMERA OPTION
				setSupportsMultipleCameras (false);
			}
		} catch (err)
		{
			console.error ('Error enumerating devices:', err);
			setSupportsMultipleCameras (false);
		}
	}

	// INITIALIZE THE AUDIO STREAM. AND ADD AUDIO TRACK TO PEER.
	const startAudioOnlyCall = async () =>
    {
		// TRY TO GET THE AUDIO STREAM.
		try
		{
			console.log ("webrtc_debug: starting audio only call");
			const audioStream = await navigator.mediaDevices.getUserMedia ({...initialAudioConstraints, video: false});
			setStream (audioStream);
			localStream.current = audioStream;
            console.log ("webrtc_debug: starting audio only call audioStream", audioStream);
			myPeer.addTrack (audioStream.getAudioTracks()[0], audioStream);
			console.log ("webrtc_debug: audio track added.")
		}
		catch (e)
		{
			console.error ("error in getting audio stream", e);
		}
    }

	const switchCam = () =>
	{
        setfrontCam (!frontCam)
        initializeCamera(true)
    }

	// THE CALLER WILL BE TRIGGERED TO END THE CALL.
	const leaveCall = async (reason) =>
	{
		console.log ("calling reject if condition", reason, ", remoteUserSocketId: "+ remoteUserSocketId)

		// CREATE THE UPDATE PAYLOAD OBJECT.
		const payload =
		{
			"action": "endtime",
			"call_id": globals.call_id,
		}
		httpRequest (`static/updatecalltime`, 'POST', payload).then();
		let endedPayload =
		{
			callendreason: (reason)? reason : "The caller has ended the call."
		}

		// STATUS UPDATE CALL ENDED IN REALTIME DATABASE.
		updateCallStatus (callId, "ENDED", endedPayload).then();

        socket.emit ("endCall", remoteUserSocketId)
        socket.off ("offer-candidate", handleIceCandidateEvent);

		// INSERT CALL DETAIL IN FIRESTORE.
		await insertCallRecordIntoFirestore (callId);

        // NAVIGATE TO THANKS-CALL OR DASHBOARD PAGE.
        navigate (isCallStatus === "OUT" ? "/thanks-call" : "/dashboard", {state: {callId: callId}});
        terminateCallConnection();
		console.log ("call ended.");
	}

	// THIS WILL BE TRIGGERED BY USER/CALLEE. END THE CALL FOR MOBILE USER AND INFORM CALLER ABOUT CALL END.
	const hangupCall = async () =>
	{
		console.log ("calling hangupCall , remoteUserSocketId: "+remoteUserSocketId)

		// SEND THE SERVER CALL ENDED EVENT IF SOCKET IS AVAILABLE
		globals.socket.emit ("endCall", remoteUserSocketId);
		socket.off ("offer-candidate", handleIceCandidateEvent);

		// CREATE THE UPDATE PAYLOAD OBJECT.
		const payload =
		{
			"action": "endtime",
			"call_id": globals.call_id,
		}
		// console.log ("Call answer payloadpayload:",payload);
		httpRequest (`static/updatecalltime`, 'POST', payload).then();
		let endedPayload =
		{
			callendreason: "The callee has ended the call."
		}

		// STATUS UPDATE CALL ENDED IN REALTIME DATABASE AND FIRESTORE DATABASE.
		await updateCallStatus (globals.call_id, "ENDED", endedPayload);
		updateCallStatusByUserId (globals.call_id, "ENDED", user.user_id).then();

		// INSERT CALL DETAIL IN FIRESTORE.
		await insertCallRecordIntoFirestore (globals.call_id);

		terminateCallConnection();
		navigate ("/dashboard");
	};

	// TERMINATE THE CALL CONNECTION.
	const terminateCallConnection = () =>
	{
		handleResetButton()

		// STOP TRACKS AND RELEASE RESOURCES
		if (stream)
		{
			// STOP LOCAL STREAM.
			stream.getTracks().forEach ((track) =>
			{
				track.stop();
			})
			setStream (null);
		}
		else
		{
			console.log("stream is null", stream);

			// STOP LOCAL STREAM. AFTER STREAM STATE IS NULL. THEN CHECK LOCAL STREAM REFERENCE.
			if (localStream.current)
			{
				console.log("localStream.current trying to null", localStream.current);

				localStream.current.getTracks().forEach ((track) =>
				{
					track.stop();
				});
				localStream.current = null;
				setStream (null);
			}
			else
			{
				console.log("localStream.current is null", localStream.current);
			}
		}
		if (myVideo.current)
		{
			myVideo.current.srcObject = null;
		}
		connectionRef && connectionRef?.current?.destroy();
		call_detail_id.current = null;
		if (myPeer)
		{
			console.log("myPeer trying to close ....");
			myPeer.oniceconnectionstatechange = null;
			myPeer.onconnectionstatechange = null;
			myPeer.onicecandidate = null;
			myPeer.ontrack = null;
			myPeer.close();
			globals.peerConnections = null;
		}
		setCallEnded (true);

		// UNSUBSCRIBE THE LISTENER TO CALL SOCKET EVENTS.
		unsubscribeToAllCallSocketEvents();
		// disconnectedSocket();
	}

	// UNSUBSCRIBE THE LISTENER TO CALL SOCKET EVENTS.
	const unsubscribeToAllCallSocketEvents = () =>
	{
		// CHECK IF GLOBALS.SOCKET IS DEFINED BEFORE TRYING TO UNSUBSCRIBE
		if (socket && socket.connected)
		{
			// UNSUBSCRIBE FROM SOCKET EVENTS
			socket.off ("staff-unavailable", handleStaffUnavailable);
			socket.off ("staff-details", handleStaffDetail);
			socket.off ("caller-details", handleCallerDetail);
			socket.off ("offer-candidate", handleIceCandidateEvent);
			socket.off ("offer", handleUserCall)
			socket.off ("updateUserMedia", handleUserMediaUpdate)
			socket.off ("answer", handleCallAccepted)
			socket.off ("endCall", handleEndCall)
			socket.off ("getCallIdFromServer", getCallIdFromServer);
		}
	}

	// DISCONNECTED SOCKET SERVER.
	const disconnectedSocket = ()=>
	{
		if (socket && socket.connected)
		{
			socket.disconnect();
			socket = null;

			// NOW RECONNECT THE SOCKET.
			socket.connect();
		}
		else
		{
			console.log ("disconnected Socket () socket null or disconnected found ");
		}
	}

	//  TOGGLE THE LOUD SPEAKER FOR AUDIO CALL.
	const switchLoudSpeakerOld = async () =>
	{
        try
        {
            const audioDevices = await navigator.mediaDevices.enumerateDevices();
            const audioOutputDevices = audioDevices.filter (device => device.kind === 'audiooutput');

            if (phoneSpeaker === 'external')
            {
                // SELECT THE PHONE SPEAKER
                await localStream.current.setAudioOutput('');
            }
            else
            {
                // SELECT THE EXTERNAL SPEAKER
                const externalSpeaker = audioOutputDevices.find (device => device.kind === 'audiooutput');
                if (externalSpeaker)
                {
                    await localStream.current.setAudioOutput (externalSpeaker.deviceId);
                } else
                {
                    console.error ('External speaker not found.');
                    // HANDLE ERROR (E.G., SHOW A MESSAGE TO THE USER)
                }
            }

            // TOGGLE THE SPEAKER STATE
            setPhoneSpeaker(prev => (prev === 'phone') ? 'external' : 'phone');
        }
        catch (error)
        {
            console.error('Error switching loudspeaker:', error);
        }
    }

	// TOGGLE THE LOUD SPEAKER FOR AUDIO CALL
	// const switchLoudSpeakerOld = async () =>
	// {
	// 	try
	// 	{
	// 		const audioDevices = await navigator.mediaDevices.enumerateDevices();
	// 		const audioOutputDevices = audioDevices.filter (device => device.kind === 'audiooutput');
	//
	// 		if (userVideo.current)
	// 		{
	// 			const audioElement = userVideo.current;
	//
	// 			if (!audioElement.setSinkId)
	// 			{
	// 				console.error ('Browser does not support output device selection.');
	// 				return;
	// 			}
	//
	// 			if (phoneSpeaker === 'external')
	// 			{
	// 				// SWITCH TO PHONE SPEAKER BY SETTING SINKID TO DEFAULT
	// 				await audioElement.setSinkId ('default');
	// 			} else
	// 			{
	// 				// FIND AN EXTERNAL SPEAKER (NOT DEFAULT)
	// 				const externalSpeaker = audioOutputDevices.find (device => device.deviceId !== 'default');
	// 				if (externalSpeaker)
	// 				{
	// 					await audioElement.setSinkId (externalSpeaker.deviceId);
	// 				} else
	// 				{
	// 					console.error ('External speaker not found.');
	// 					// Handle error (e.g., show a message to the user)
	// 				}
	// 			}
	//
	// 			// TOGGLE THE SPEAKER STATE
	// 			setPhoneSpeaker (prev => (prev === 'phone') ? 'external' : 'phone');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error switching loudspeaker:', error);
	// 	}
	// };

	// GET THE AUDIO OUTPUT DEVICES.
	async function getAudioOutputDevices()
	{
		try
		{
			const devices = await navigator.mediaDevices.enumerateDevices();
			const audioOutputs = devices.filter (device => device.kind === 'audiooutput');
			console.log ("audioOutputs", audioOutputs);
			return audioOutputs;
		}
		catch (error)
		{
			console.error('Error enumerating devices:', error);
			return [];
		}
	}

	// TOGGLE THE LOUDSPEAKER FOR AUDIO CALL
	const switchLoudSpeaker = async () =>
	{
		try
		{
			// GET THE AUDIO OUTPUT DEVICES
			const audioOutputDevices = await getAudioOutputDevices();

			// FIND THE LOUDSPEAKER AND PHONE SPEAKER THEN CHECK DEVICE AVAILABILITY
			const loudspeaker = audioOutputDevices.find(device => device.deviceId === 'default');
			const phoneSpeaker = audioOutputDevices.find(device => device.deviceId !== 'default');
			console.log ("loudspeaker", loudspeaker, "phoneSpeaker", phoneSpeaker);

			if (loudspeaker || phoneSpeaker)
			{
				// CHECK USER VIDEO ELEMENT IS AVAILABLE.
				if (userVideo.current)
				{
					const audioElement = userVideo.current;

					// CHECK IF BROWSER SUPPORTS OUTPUT DEVICE SELECTION
					if (!audioElement.setSinkId)
					{
						console.error ('Browser does not support output device selection.');
						return;
					}

					// SELECT THE DEVICE BASED ON THE CURRENT SPEAKER STATE
					const selectedDevice = phoneSpeaker === 'external' ? loudspeaker.deviceId : phoneSpeaker.deviceId;

					try
					{
						await audioElement.setSinkId (selectedDevice);
						console.log(`Switched to ${(phoneSpeaker === 'external') ? 'loudspeaker' : 'phone speaker'}`);
					}
					catch (error)
					{
						console.error('Error setting sink ID:', error);
					}
				}
				else
				{
					console.error ('userVideo not found.');
				}
			}
			else
			{
				console.error ('Suitable audio output devices not found.');
			}

			// TOGGLE THE SPEAKER STATE
			setPhoneSpeaker (prev => (prev === 'external' ? 'phone' : 'external'));
		} catch (error) {
			console.error('Error switching loudspeaker:', error);
		}
	};

	return (
    <VideoContext.Provider
        value={{
	        switchCam,
	        isSafariBrowser,
	        staffTimeOut,
	        callRejected,
	        sec,
	        call,
	        callAccepted,
	        myVideo,
	        userVideo,
	        stream,
	        remoteUsername,
	        callEnded,
	        me,
	        leaveCall,
	        setRemoteUserSocketId,
            username,
	        myVdoStatus,
	        setMyVdoStatus,
	        userVdoStatus,
	        setUserVdoStatus,
	        updateVideo,
	        myMicStatus,
	        userMicStatus,
	        updateMic,
	        remoteUserSocketId,
            staffUnavailable,
	        message,
	        callStatus,
	        hangupCall,
	        callType,
	        isCallStatus,
	        toggleVideoCall,
	        switchLoudSpeaker,
	        phoneSpeaker,
	        isItVideoCall,
	        supportsMultipleCameras
		}}
    >
		<>
	      {children}
		</>
    </VideoContext.Provider>
  )
}

export default VideoState
