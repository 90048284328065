/*
 	AUTHOR: QASIM ZUBAIR
 	SUMMARY: THE COMMON VARIABLES WILL BE STORED HERE. THAT ARE ACCESSIBLE IN WHOLE APP.
 */

// EXPORT GLOBAL VARIABLES TO THE APPLICATION.
export const globals: any =
	{
		socket: null,
		user_id: null, // USER ID OF THE LOGGED IN USER.
		email: null, // USER ID OF THE LOGGED IN USER.
		language_id: 1, // USER SELECTED LANGUAGE FOR THE APP.
		device_id: null, // UNIQUE FCM TOKEN TO REPRESENT THE CURRENT DEVICE.
		logList: [],
		currentScreenName: null, // NAME OF THE CURRENT ACTIVE SCREEN.
		request_from: "web",

		// CALLER DETAIL
		hasAnswered: false,
		callerSocketID: null,
		callerUserName: null,
		callStartTime: null,
		call_id: null,
		isThisDefaultStaffNotified: false,
		maxResponseSeconds: 45, // MAXIMUM TIME TO RESPOND TO THE INCOMING CALL.
		callStaffData: null,
		callerDetail: {},
		userFromNotification: false,
		notification_received_time: null,
		peerConnections: null, // STORE PEER CONNECTION OBJECT.
		localUser: {},
		remoteUsers: {},
		isCallActive: false,
		activeCallId: 0,
		activeCallStatus: null,
		remoteStreams: null,
		localStreams: null,
		appVersion: 0.71,// CURRENT APP VERSION.
		lastUpdated: "14/11/24", // WHEN WAS THIS APP UPDATED.
		hasUserInteractedWithDocument: false, // HAS USER INTERACTED WITH DOCUMENT.
	}
